// 小程序管理的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/appletsManage',   // 预约直播
        name: 'appletsManage',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/appletsManage/liveBroadcast",
        meta: { title: '小程序管理' },
        children: [
            {
                path: '/appletsManage/liveBroadcast',  // 预约直播
                name: 'liveBroadcast',
                meta: { title: '预约直播', isShow: true, },
                component: () => import(/* webpackChunkName: "LiveBroadcast" */ '@/views/appletsManage/live/LiveBroadcast.vue'),
                children: [
                    {
                        path: '/appletsManage/liveBroadcastEidt/:id',  // 预约编辑
                        name: 'liveBroadcastEidt',
                        meta: { title: '预约直播新增'},
                        component: () => import(/* webpackChunkName: "LiveBroadcastEidt" */ '@/views/appletsManage/live/LiveBroadcastEidt.vue'),
                    },
                ]
            },
            {
                path: '/appletsManage/sharePoster',  // 分享海报管理
                name: 'sharePoster',
                meta: { title: '分享海报管理', isShow: true, },
                component: () => import(/* webpackChunkName: "sharePoster" */ '@/views/appletsManage/sharePoster/index.vue'),
                children: [
                    {
                        path: '/appletsManage/sharePoster/add/:id',
                        name: 'addSharePoster',
                        meta: { title: '添加/修改' },
                        component: () => import(/* webpackChunkName: "sharePosterAdd" */ '@/views/appletsManage/sharePoster/add.vue'),
                    },
                ]
            },
            {
                path: '/appletsManage/yunying',  // 服务中心小程序
                name: 'yunying',
                meta: { title: '服务中心小程序', isShow: true, },
                component: () => import(/* webpackChunkName: "yunying" */ '@/views/appletsManage/yunying/Yinying.vue'),
                children: [
                    {
                        path: '/appletsManage/liveIntroduce',  // 直播介绍管理
                        name: 'liveIntroduce',
                        meta: { title: '直播介绍' },
                        component: () => import(/* webpackChunkName: "liveIntroduce" */ '@/views/appletsManage/yunying/components/introduce/LiveIntroduce.vue'),
                    },
                    {
                        path: '/appletsManage/subject',  // 活动对话
                        name: 'subject',
                        meta: { title: '活动对话'},
                        component: () => import(/* webpackChunkName: "subject" */ '@/views/appletsManage/yunying/components/subject/subject.vue'),
                    },
                    {
                        path: '/appletsManage/subjectEdit/:id',  // 活动对话新增编辑
                        name: 'subjectEdit',
                        meta: { title: '活动对话新增/编辑'},
                        component: () => import(/* webpackChunkName: "subjectEdit" */ '@/views/appletsManage/yunying/components/subject/subjectEdit.vue'),
                    },
                    {
                        path: '/appletsManage/mzYdt',  // 名著一点通
                        name: 'mzYdt',
                        meta: { title: '四大名著21天优学营'},
                        component: () => import(/* webpackChunkName: "mzYdt" */ '@/views/appletsManage/yunying/components/mzYdt/mzYdt.vue'),
                    },
                    {
                        path: '/appletsManage/mzYdtEdit/:id',  // 名著一点通新增编辑
                        name: 'mzYdtEdit',
                        meta: { title: '四大名著21天优学营新增/编辑'},
                        component: () => import(/* webpackChunkName: "mzYdtEdit" */ '@/views/appletsManage/yunying/components/mzYdt/mzYdtEdit.vue'),
                    },
                    {
                        path: '/appletsManage/perspective',  // 看法管理
                        name: 'perspective',
                        meta: { title: '看法管理' },
                        component: () => import(/* webpackChunkName: "perspective" */ '@/views/appletsManage/yunying/components/perspective/Perspective.vue'),
                    },
                    {
                      path: '/appletsManage/classicsYdt',  // 名著一点通
                      name: 'classicsYdt',
                      meta: { title: '名著一点通'},
                      component: () => import(/* webpackChunkName: "classicsYdt" */ '@/views/appletsManage/yunying/components/classicsYdt/classicsYdt.vue'),
                  },
                  {
                      path: '/appletsManage/classicsYdtEdit/:id',  // 名著一点通新增编辑
                      name: 'classicsYdtEdit',
                      meta: { title: '名著一点通新增/编辑'},
                      component: () => import(/* webpackChunkName: "mzYdtEdit" */ '@/views/appletsManage/yunying/components/classicsYdt/classicsYdtEdit.vue'),
                  },
                ],
            }
        ]
    },
]
