/*
 * @Description:
 * @Author: shaojing
 * @Date: 2022-05-18 20:48:35
 * @LastEditors: shaojing
 * @LastEditTime: 2022-05-25 15:47:30
 */
// 首页管理的路由
import Vue from 'vue';
import Router from 'vue-router'

Vue.use(Router);

export default [
    {
        path: "/indexManage", // 首页管理
        name: "indexManage",
        component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
        redirect: "/indexManage/indexConfig",
        meta: {title: "APP管理"},
        children: [
            {
                path: "/indexManage/indexConfig", // 首页配置路由
                name: "indexConfig",
                meta: {title: "首页配置", isShow: true},
                component: () =>
                    import(
                        /* webpackChunkName: "roleManage" */ "@/views/indexManage/IndexConfig.vue"
                        ),
                children: [
                    {
                        path: "/indexManage/indexConfig/addBanner/:id", // 新建banner路由
                        name: "addBanner",
                        meta: {title: "新建/编辑banner"},
                        component: () =>
                            import(
                                /* webpackChunkName: "addBanner" */ "@/views/indexManage/AddBanner.vue"
                                ),
                    },
                    {
                        path: "/indexManage/indexConfig/addHotLessons/:id", // 新建精品馆路由
                        name: "addHotLessons",
                        meta: {title: "新建/编辑精品馆"},
                        component: () =>
                            import(
                                /* webpackChunkName: "addHotLessons" */ "@/views/indexManage/AddHotLessons.vue"
                                ),
                    },
                    {
                        path: "/indexManage/indexConfig/addTheDaily/:id", // 新建没日申识路由
                        name: "addTheDaily",
                        meta: {title: "新建/编辑每日申识"},
                        component: () =>
                            import(
                                /* webpackChunkName: "addTheDaily" */ "@/views/indexManage/AddTheDaily.vue"
                                ),
                    },
                ],
            },
            {
                path: "/indexManage/exercisePop", // 活动弹窗设置
                name: "exercisePop",
                meta: {title: "活动弹窗设置", isShow: true},
                component: () =>
                    import(
                        /* webpackChunkName: "ExercisePop" */ "@/views/indexManage/ExercisePop.vue"
                        ),
                children: [
                    {
                        path: "/indexManage/exercisePop/exercisePopEdit/:id",
                        name: "exercisePopEdit",
                        meta: {title: "新建/编辑活动弹窗"},
                        component: () =>
                            import(
                                /* webpackChunkName: "ExercisePopEdit" */ "@/views/indexManage/ExercisePopEdit.vue"
                                ),
                    },
                ],
            },
            {
                path: "/indexManage/groupingManage", // 分组管理路由
                name: "groupingManage",
                meta: {title: "分组管理"},
                component: () =>
                    import(
                        /* webpackChunkName: "groupingManage" */ "@/views/indexManage/GroupingManage.vue"
                        ),
            },
            {
                path: "/indexManage/indexCategory", //首页分类管理
                name: "indexCategory",
                meta: {title: "首页分类管理", isShow: true},
                component: () => import( /* webpackChunkName: "indexCategory" */ "@/views/indexManage/IndexCategory.vue"),
                children: [
                    {
                        path: "/indexManage/indexCategory/addCategory/:id", // 新建/编辑分类
                        name: "indexAddCategory",
                        meta: {title: "新建/编辑分类"},
                        component: () => import("@/views/indexManage/AddCategory.vue"),
                    },
                ],
            },
            {
                path: "/indexManage/indexBannerList", //首页分类管理
                name: "indexCategory",
                meta: {title: "banner管理", isShow: true},
                component: () =>
                    import(
                        /* webpackChunkName: "indexCategory" */ "@/views/indexManage/IndexBannerList.vue"
                        ),
                children: [
                    {
                        path: "/indexManage/indexBannerList/addBanner/:id", // 新建banner路由
                        name: "addBanner",
                        meta: {title: "新建/编辑banner"},
                        component: () =>
                            import(
                                /* webpackChunkName: "addBanner" */ "@/views/indexManage/AddBannerItem.vue"
                                ),
                    },
                ],
            },
            {
                path: "/indexManage/playbill/playbillList", //海报管理
                name: "playbillList",
                meta: {title: "海报管理", isShow: true},
                component: () =>
                    import(
                        /* webpackChunkName: "PlaybillList" */ "@/views/indexManage/playbill/PlaybillList.vue"
                        ),
                children: [
                    {
                        path: "/indexManage/playbill/playbillEdit/:id", // 新建banner路由
                        name: "playbillEdit",
                        meta: {title: "新建/编辑海报"},
                        component: () =>
                            import(
                                /* webpackChunkName: "PlaybillEdit" */ "@/views/indexManage/playbill/PlaybillEdit.vue"
                                ),
                    },
                ],
            },
            {
                path: "/indexManage/indexOperate", // 首页运营位
                name: "indexOperate",
                meta: { title: "首页运营位", isShow: true},
                component: () => import('@/views/indexManage/indexOperate.vue'),
                children: [
                    {
                        path: "/indexManage/indexOperate/addOperate/:id", // 新建/编辑分类
                        name: "operateAdd",
                        meta: {title: "新建/编辑"},
                        component: () => import("@/views/indexManage/AddOperate.vue"),
                    },
                ],
            },
            {
                path: "/indexManage/signPoster", // 签到海报管理
                name: "signPoster",
                meta: { title: "签到海报管理", isShow: true},
                component: () => import('@/views/indexManage/signPoster/index.vue'),
                children: [
                    {
                        path: "add/:id", // 新建/编辑分类
                        name: "addSignPoster",
                        meta: {title: "新建/编辑"},
                        component: () => import("@/views/indexManage/signPoster/add.vue"),
                    },
                ],
            },
            {
              path: "/indexManage/recommendation", // 首页推荐管理
              name: "recommendation",
              meta: { title: "首页推荐管理", isShow: true},
              component: () => import('@/views/indexManage/recommendation/index.vue')
            }
        ],
    },
];
