// 营销中心的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/marketingCenter',   // 营销中心
        name: 'marketingCenter',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/marketingCenter/inviteCode",
        meta: { title: '营销中心' },
        children: [
            {
                path: '/marketingCenter/inviteCode',  // 邀请码路由
                name: 'inviteCode',
                meta: { title: '邀请码', isShow: true },
                component: () => import('@/views/marketingCenter/InviteCode.vue'),
                children: [
                    {
                        path: "/marketingCenter/inviteCode/actionInviteCode/:id", // 添加/编辑邀请码
                        name: "actionInviteCode",
                        meta: { title: '添加/编辑邀请码' },
                        component: () => import('@/views/marketingCenter/InviteCode/ActionInviteCode.vue')
                    },
                    {
                        path: "/marketingCenter/inviteCode/usedCode/:data", // 邀请码使用记录
                        name: "usedCode",
                        meta: { title: '使用记录' },
                        component: () => import('@/views/marketingCenter/InviteCode/UsedCode.vue')
                    }
                ]
            },
            {
                path: '/marketingCenter/InviteMember',  // 线下邀请码（会员卡）路由
                name: '/marketingCenter/InviteMember',
                meta: { title: '线下码', isShow: true },
                component: () => import('@/views/marketingCenter/inviteMemberCodeUseList.vue'),
                // children: [
                //     {
                //         path: "/marketingCenter/inviteCode/usedCode/:data", // 邀请码使用记录
                //         name: "usedCode",
                //         meta: { title: '使用记录' },
                //         component: () => import('@/views/marketingCenter/InviteCode/UsedCode.vue')
                //     }
                // ]
            },
            {
                path: '/marketingCenter/InviteOnline',  // 线上推广码路由
                name: '/marketingCenter/InviteOnline',
                meta: { title: '线上码', isShow: true },
                component: () => import('@/views/marketingCenter/inviteOnlineCode.vue')
            },
            {
                path: '/marketingCenter/memberManage',  // 会员管理路由
                name: 'memberManage',
                meta: { title: '会员管理', isShow: true },
                component: () => import('@/views/marketingCenter/MemberManage.vue'),
                children: [
                    {
                        path: "/marketingCenter/memberManage/memberAction/:id", // 添加/编辑会员
                        name: "memberAction",
                        meta: { title: '添加/编辑会员' },
                        component: () => import('@/views/marketingCenter/MemberManage/MemberAction.vue')
                    },
                    {
                        path: "/marketingCenter/memberManage/memberDetail/:memberNo", // 使用详情
                        name: "usedCode",
                        meta: { title: '使用详情' },
                        component: () => import('@/views/marketingCenter/MemberManage/MemberDetail.vue')
                    }
                ]
            },
            {
                path: '/marketingCenter/proxyManage',  // 代理管理路由
                name: 'proxyManage',
                meta: { title: '代理管理', isShow: true },
                component: () => import(/* webpackChunkName: "proxyManage" */ '@/views/marketingCenter/ProxyManage.vue'),  // 
                children: [
                    {
                        path: "/marketingCenter/proxyManage/addProxy/:id", // 添加/编辑代理列表
                        name: "addProxy",
                        meta: { title: '添加/编辑代理' },
                        component: () => import('@/views/marketingCenter/AddProxy.vue')
                    },
                    {
                        path: "/marketingCenter/proxyManage/proxycommission/:agentUserId/:realName/:agentRegion/:agentName/:createTime/:commissionSum",  // 代理佣金明细
                        name: "proxycommission",   //  佣金
                        meta: { title: '代理佣金明细' },
                        component: () => import('@/views/marketingCenter/Proxycommission.vue')
                    },   // AddProxyConfig
                    {
                        path: "/marketingCenter/proxyManage/addProxyConfig/:id",  // 添加/编辑代理配置
                        name: "addProxyConfig",
                        meta: { title: '添加代理等级' },
                        component: () => import('@/views/marketingCenter/AddProxyConfig.vue')
                    }
                ]
            },
            {
                path: '/marketingCenter/couponManage',  // 优惠券管理路由
                name: 'couponManage',
                meta: { title: '优惠券管理', isShow: true },
                component: () => import(/* webpackChunkName: "couponManage" */ '@/views/marketingCenter/couponManageList.vue'),
                children: [
                    {
                        path: "/marketingCenter/couponManage/addCoupon/:id", // 添加/编辑优惠券
                        name: "addCoupon",
                        meta: { title: '添加/编辑优惠券' },
                        component: () => import('@/views/marketingCenter/couponManageAddCoupon.vue')
                    },
                    {
                        path: "/marketingCenter/couponManage/viewCoupon/:id", // 查看优惠券
                        name: "viewCoupon",
                        meta: { title: '查看优惠券' },
                        component: () => import('@/views/marketingCenter/couponManageAddCoupon.vue')
                    },
                    {
                        path: "/marketingCenter/couponManage/usedDetail/:id",  // 使用详情
                        name: "couponUsedDetail",
                        meta: { title: '优惠券使用详情' },
                        component: () => import('@/views/marketingCenter/couponManageUsedDetail.vue')
                    },
                ]
            },
            {
                path: '/marketingCenter/taskCenter',  // 任务中心
                name: 'TaskCenter',
                meta: { title: '任务中心', isShow: true },
                component: () => import('@/views/marketingCenter/taskCenter/index.vue'),
                children: [
                    {
                        path: 'edit/:taskId',  // 
                        name: 'EditTask',
                        meta: { title: '编辑' },
                        component: () => import('@/views/marketingCenter/taskCenter/edit.vue'),
                    }
                ]
            },
        ]
    },
]