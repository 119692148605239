// 报表中心的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
  {
    path: '/reportFormManage',   // 报表中心
    name: 'reportFormManage',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    redirect: "/reportFormManage/transactionIndex",
    meta: { title: '报表中心' },
    children: [
      {
        path: '/reportFormManage/transactionIndex',  // 业务数据报表
        name: 'transactionIndex',
        meta: { title: '业务数据报表', isShow: true, },
        component: () => import(/* webpackChunkName: "TransactionIndex" */ '@/views/reportFormManage/transaction/TransactionIndex.vue'),
      },
      {
        path: '/reportFormManage/contentIndex',  // 内容数据报表
        name: 'contentIndex',
        meta: { title: '内容数据报表', isShow: true, },
        component: () => import(/* webpackChunkName: "ContentIndex" */ '@/views/reportFormManage/content/ContentIndex.vue'),
      },
      {
        path: '/reportFormManage/operateIndex',  // 运营数据报表
        name: 'operateIndex',
        meta: { title: '运营数据报表', isShow: true, },
        component: () => import(/* webpackChunkName: "OperateIndex" */ '@/views/reportFormManage/operate/OperateIndex.vue'),
      },
      {
        path: '/reportFormManage/playData',  // 产品播放数据报表
        name: 'playData',
        meta: { title: '产品播放数据报表', isShow: true, },
        component: () => import(/* webpackChunkName: "PlayData" */ '@/views/reportFormManage/playData/PlayData.vue'),
      },
      {
        path: '/reportFormManage/performanceIndax',  // 销售业绩核算订单
        name: 'performanceIndax',
        meta: { title: '销售业绩核算订单', isShow: true, },
        component: () => import(/* webpackChunkName: "PerformanceIndax" */ '@/views/reportFormManage/performance/PerformanceIndax.vue'),
      },
      {
        path: '/reportFormManage/SalesPlayback',  // 销售和播放数据
        name: 'SalesPlayback',
        meta: { title: '销售和播放数据', isShow: true, },
        component: () => import(/* webpackChunkName: "SalesPlayback" */ '@/views/reportFormManage/salesPlayback/SalesPlayback.vue'),
      }
    ]
  },
]
