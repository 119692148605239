// 设置的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/saleManage',   // 销售中心
        name: 'saleManage',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/saleManage/channelSales",
        meta: { title: '销售中心' },
        children: [
            {
                path: '/saleManage/channelSales',  // 渠道销售数据
                name: 'channelSales',
                meta: { title: '渠道销售数据汇总', isShow: true },
                component: () => import(/* webpackChunkName: "ChannelSales" */ '@/views/saleManage/ChannelSales.vue'),
            },
            {
                path: '/saleManage/userPromotion',  // 用户推广业绩
                name: 'userPromotion',
                meta: { title: '用户推广业绩汇总', isShow: true },
                component: () => import(/* webpackChunkName: "UserPromotion" */ '@/views/saleManage/UserPromotion.vue'),
            },
            {
                path: '/saleManage/readingAmbassador',  // 阅读大使数据
                name: 'readingAmbassador',
                meta: { title: '阅读大使推广业绩汇总', isShow: true },
                component: () => import(/* webpackChunkName: "UserPromotion" */ '@/views/saleManage/ReadingAmbassador.vue'),
            },
            {
                path: '/saleManage/itemCensus',  // 各销售平台统计数据
                name: 'itemCensus',
                meta: { title: '各销售平台统计数据', isShow: true },
                component: () => import(/* webpackChunkName: "UserPromotion" */ '@/views/saleManage/ItemCensus.vue'),
            },
        ]
    },
]
