import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/physicalCard',
        name: 'physicalCard',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/physicalCard/physicalCardList",
        meta: { title: '商品中心' },
        children: [
            {
                path: '/physicalCard/physicalCardList',
                name: 'physicalCardList',
                meta: { title: '渠道直销商品列表', isShow: true, },
                component: () => import(/* webpackChunkName: "physicalCardList" */ '@/views/physicalCard/PhysicalCardList.vue'),
                children: [
                    {
                        path: '/physicalCard/physicalCardList/physicalCardEdit/:id/:type/',
                        name: 'physicalCardEdit',
                        meta: { title: '添加/编辑渠道直销商品'},
                        component: () => import(/* webpackChunkName: "addTalk" */ '@/views/physicalCard/PhysicalCardEdit.vue'),
                    },
                ]
            },
            {
                path: '/physicalCard/physicalGoods',
                name: 'physicalGoods',
                meta: { title: '实体商品列表', isShow: true, },
                component: () => import('@/views/physicalCard/physicalGoods/index.vue'),
                children: [
                    {
                        path: 'add/:productId',
                        name: 'productId',
                        meta: { title: '添加/编辑'},
                        component: () => import('@/views/physicalCard/physicalGoods/addGoods.vue')
                    }
                ]
            },
            {
                path: '/physicalCard/goodsManage',
                name: 'goodsManage',
                meta: { title: '实体商品分类管理', isShow: true, },
                component: () => import('@/views/physicalCard/physicalGoods/typeList.vue'),
            }
        ]
    },
]
