import Vue from 'vue'
import VueRouter from 'vue-router'
import RolePower from './rolePower/router'
import CoursManage from './coursManage/router'
import IndexManage from './indexManage/router'
import TeachersManage from './teachersManage/router'
import Crm from './crm/router'
import OrderformManage from './orderformManage/router'
import MarketingCenter from './marketingCenter/router'
import UserFeedback from './userFeedback/router'
import SetUp from './SetUp/router'
import SystemManage from './systemManage/router'
import CircleManage from './circleManage/router'
import VideoManage from './videoManage/router'
import PhysicalCard from './physicalCard/router'
import UserManage from './userManage/router'
import SaleManage from './saleManage/router'
import AppletsManage from './appletsManage/router'
import NoticeManage from './noticeManage/router'
import OperateCenter from './operateCenter/router'
import ReportFormManage from './reportFormManage/router'
import researchLearning from './researchLearning/index'
// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
};

Vue.use(VueRouter);
// 所有路由集合
let allRouterArr = [
    ...RolePower,
    ...CoursManage,
    ...IndexManage,
    ...TeachersManage,
    ...Crm,
    ...OrderformManage,
    ...MarketingCenter,
    ...UserFeedback,
    ...SetUp,
    ...SystemManage,
    ...CircleManage,
    ...VideoManage,
    ...PhysicalCard,
    ...UserManage,
    ...SaleManage,
    ...AppletsManage,
    ...NoticeManage,
    ...OperateCenter,
    ...ReportFormManage,
    ...researchLearning
]
//  动态路由
let powerListArr = JSON.parse(localStorage.getItem("powerListArr"))
if (powerListArr) {
  let powerListObj = powerListArr.reduce((pre, current) => {
    pre[current.code] = "xxx";
    return pre;
  }, {});
  let childPowerListArr = [];
  powerListArr.map(item => {
    if (item.children.length > 0) {
      item.children.map(subitem => {
        childPowerListArr.push(subitem);
      })
    }
  })
  let childPowerListObj = childPowerListArr.reduce((pre, current) => {
    pre[current.code] = "xxx";
    return pre;
  }, {})
  let newAllRouterArr = [];   // 过滤后的路由数组
  allRouterArr.map(item => {
    let subNewAllRouterArr = [];
    if (powerListObj[item.name]) {
      newAllRouterArr.push(item);
      item.children.forEach(subItem => {
        if (childPowerListObj[subItem.name]) {
          subNewAllRouterArr.push(subItem);
        }
      })
      if (item.children.length > 0) {
        item.children = subNewAllRouterArr;
      }
    }
  });
  allRouterArr = newAllRouterArr;
}


const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',  // 登录页
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/home',  // 首页
    name: 'home',
    redirect: "/home/home",
    meta: { title: '首页' },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    children: [
      {
        path: '/home/home',  // 控制台
        name: 'subHome',
        meta: { title: '控制台' },
        component: () => import(/* webpackChunkName: "subHome" */ '@/views/public/Home.vue')
      },
    ]
  },
  {
    path: '/home',
    name: 'home',
    redirect: "/home/home",
    meta: { title: '首页' },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    children: [
      {
        path: '/home/noPower',  // 没有权限
        name: 'noPower',
        component: () => import(/* webpackChunkName: "noPower" */ '@/views/public/NoPower.vue')
      },
    ]
  },
  {
    path: '/nothingness',  // 404
    name: 'nothingness',
    component: () => import(/* webpackChunkName: "nothingness" */ '@/views/public/Nothingness.vue')
  },
  ...allRouterArr,  // Nothingness
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 导航守卫
const passRouter = ['/', '/login']
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token')
  if (token) {
    next()
  } else {
    const flag = !passRouter.includes(to.path) && passRouter.includes(from.path)
    flag ? next({ path: '/' }) : next()
  }
  // if (token && !store.state.dictionary.DictionaryObj.pointType) {
  //   // 全局数据字典
  //   DictionaryApi().then(({ code, data }) => {
  //     if (code == 200) {
  //       store._mutations["dictionary/changeDictionaryObj"][0](data);
  //     }
  //   })
  // }
})
export default router
