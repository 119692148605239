const app = {
  state: {
    sideCollapsed: false
  },
  mutations: {
    sidebarType: (state, type) => {
      state.sideCollapsed = type
      localStorage.setItem('sidebar_type', type)
    }
  }
}

export default app
