// 用户反馈的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/userFeedback',   // 用户反馈
        name: 'userFeedback',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/userFeedback/feedbackList",
        meta: { title: '用户反馈' },
        children: [
            {
                path: '/userFeedback/feedbackList',  // 反馈列表
                name: 'feedbackList',
                meta: { title: '反馈列表', isShow: true },
                component: () => import(/* webpackChunkName: "feedbackList" */ '@/views/userFeedback/FeedbackList.vue'),  //FeedbackDetail
                children: [
                    {
                        path: '/userFeedback/feedbackList/feedbackDetail/:id',  // 反馈列表
                        name: 'feedbackDetail',
                        meta: { title: '反馈详情' },
                        component: () => import(/* webpackChunkName: "feedbackList" */ '@/views/userFeedback/FeedbackDetail.vue'),
                    }
                ]
            },
        ]
    },
]