// 消息管理的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/noticeManage',   // 通知管理
        name: 'noticeManage',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/noticeManage/noticeIndex",
        meta: { title: '通知管理' },
        children: [
            {
                path: '/noticeManage/noticeIndex',  // 消息推送
                name: 'noticeIndex',
                meta: { title: '消息推送', isShow: true, },
                component: () => import(/* webpackChunkName: "NoticeIndex" */ '@/views/noticeManage/NoticeIndex.vue'),
                children: [
                    {
                        path: '/noticeManage/noticeEdit/:id',  // 消息推送详情
                        name: 'noticeEdit',
                        meta: { title: '消息推送新增/编辑'},
                        component: () => import(/* webpackChunkName: "NoticeEdit" */ '@/views/noticeManage/NoticeEdit.vue'),
                    },
                ]
            },
        ]
    },
]
