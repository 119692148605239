// 设置的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/setUp',   // 设置
        name: 'setUp',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/setUp/commonProblem",
        meta: { title: '设置' },
        children: [
            {
                path: '/setUp/commonProblem',  // 常见问题路由
                name: 'commonProblem',
                meta: { title: '常见问题', isShow: true },
                component: () => import(/* webpackChunkName: "commonProblem" */ '@/views/setUp/CommonProblem.vue'),
                children: [
                    {
                        path: '/setUp/commonProblem/addProblem/:id',  // 添加/编辑问题路由
                        name: 'addProblem',
                        meta: { title: '添加/编辑问题' },
                        component: () => import(/* webpackChunkName: "commonProblem" */ '@/views/setUp/AddProblem.vue'),
                    }
                ]
            },
        ]
    },
]