// 师资管理的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/teachersManage',   // 师资管理
        name: 'teachersManage',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/teachersManage/teacherList",
        meta: { title: '师资管理' },
        children: [
            {
                path: '/teachersManage/teacherList',  // 教师列表
                name: 'teacherList',
                meta: { title: '教师列表', isShow: true },
                component: () => import(/* webpackChunkName: "teacherList" */ '@/views/teachersManage/TeacherList.vue'),
                children: [
                    {
                        path: "/teachersManage/teacherList/addTeacher/:id", // 添加教师路由
                        name: "addTeacher",
                        meta: { title: '添加/编辑教师' },
                        component: () => import(/* webpackChunkName: "addTeacher" */ '@/views/teachersManage/AddTeacher.vue'),
                    },
                ]
            },  // TeacherTag
            {
                path: '/teachersManage/teacherTag',  // 教师标签
                name: 'teacherTag',
                meta: { title: '教师标签' },
                component: () => import(/* webpackChunkName: "TeacherTag" */ '@/views/teachersManage/TeacherTag.vue')
            },
        ]
    },
]