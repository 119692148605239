// CRM 的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/crm',   // CRM
        name: 'crm',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/crm/clientManage",
        meta: { title: 'CRM' },
        children: [
            {
                path: '/crm/clientManage',  // 客户管理路由
                name: 'clientManage',
                meta: { title: '客户管理' },
                component: () => import(/* webpackChunkName: "ClientManage" */ '@/views/crm/ClientManage.vue')
            },
            {
                path: '/crm/ClientManageSearch',  // 客户管理路由
                name: 'ClientManageSearch',
                meta: { title: '客户管理（仅搜索）' },
                component: () => import(/* webpackChunkName: "ClientManage" */ '@/views/crm/ClientManageSearch.vue')
            },
            {
                path: '/crm/classManage',  // 班级管理路由
                name: 'classManage',
                meta: { title: '班级管理' },
                component: () => import(/* webpackChunkName: "classManage" */ '@/views/crm/ClassManage.vue')
            },
            {
                path: '/crm/studyData',  // 学习数据路由
                name: 'studyData',
                meta: { title: '学习数据', isShow: true },
                component: () => import(/* webpackChunkName: "studyData" */ '@/views/crm/StudyData.vue'),
                children: [
                    {
                        path: '/crm/studyData/studyDetail',  // 学习详情
                        name: 'studyDetail',
                        meta: { title: '学习详情' },
                        component: () => import(/* webpackChunkName: "studyDetail" */ '@/views/crm/studyData/StudyDetail.vue')
                    },
                ]
            },
        ]
    },
]