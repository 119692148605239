export default [
  {
    path: '/operateCenter',
    name: 'operateCenter',
    component: () => import('@/views/Home.vue'),
    redirect: "/operateCenter/integralConfig",
    meta: { title: '运营中心' },
    children: [
      {
        path: 'integralConfig',
        name: 'integralConfig',
        meta: { title: '积分获取配置', isShow: true, },
        component: () => import( '@/views/operateCenter/integralConfig.vue'),
      },
      {
        path: 'userIntegralList',
        name: 'userIntegralList',
        meta: { title: '用户积分列表', isShow: true, },
        component: () => import( '@/views/operateCenter/userIntegralList/list.vue'),
        children: [
          {
            path: 'detail/:userNo',
            meta: { title: '可用积分操作记录'},
            component: () => import('@/views/operateCenter/userIntegralList/detail.vue')
          }
        ]
      }
    ]
  }
]
