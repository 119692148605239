// 用户管理的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/userManage',   // 用户中心
        name: 'userManage',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/userManage/userList",
        meta: { title: '用户中心' },
        children: [
            {
                path: '/userManage/userList',  // 用户中心
                name: 'userList',
                meta: { title: '用户管理', isShow: true, },
                component: () => import(/* webpackChunkName: "userList" */ '@/views/userManage/user/UserList.vue'),
                children: [
                    {
                        path: '/userManage/userList/userDetails/:id',  // 用户详情
                        name: 'userDetails',
                        meta: { title: '用户详情'},
                        component: () => import(/* webpackChunkName: "UserDetails" */ '@/views/userManage/user/UserDetails.vue'),
                    },
                ]
            },
            {
                path: '/userManage/UserListSearch',  // 用户中心
                name: 'UserListSearch',
                meta: { title: '用户管理', isShow: true, },
                component: () => import(/* webpackChunkName: "userList" */ '@/views/userManage/user/UserListSearch.vue'),
                children: [
                    {
                        path: '/userManage/userList/userDetails/:id',  // 用户详情
                        name: 'userDetails',
                        meta: { title: '用户详情'},
                        component: () => import(/* webpackChunkName: "UserDetails" */ '@/views/userManage/user/UserDetails.vue'),
                    },
                ]
            },
            {
                path: '/userManage/legalRightList',  // 用户权益变更查询
                name: 'legalRightList',
                meta: { title: '用户权益变更', isShow: true, },
                component: () => import(/* webpackChunkName: "LegalRightList" */ '@/views/userManage/legalRight/LegalRightList.vue'),
                children: [
                    {
                        path: '/userManage/LegalRightList/LegalRightDetails/:id',  // 用户权益变更查询详情
                        name: 'legalRightDetails',
                        meta: { title: '用户权益变更详情'},
                        component: () => import(/* webpackChunkName: "LegalRightDetails" */ '@/views/userManage/legalRight/LegalRightDetails.vue'),
                    },
                ]
            },
            {
                path: '/userManage/attributedToList',  // 用户归属划分
                name: 'attributedToList',
                meta: { title: '用户归属划分', isShow: true, },
                component: () => import(/* webpackChunkName: "AttributedToList" */ '@/views/userManage/attributedTo/AttributedToList.vue'),
                children: [
                    {
                        path: '/userManage/attributedToList/attributedToDetails',  // 用户归属划分记录
                        name: 'attributedToDetails',
                        meta: { title: '用户归属划分记录'},
                        component: () => import(/* webpackChunkName: "AttributedToDetails" */ '@/views/userManage/attributedTo/AttributedToDetails.vue'),
                    },
                ]
            },
            {
                path: '/userManage/ownershipList',  // 用户归属变更
                name: 'ownershipList',
                meta: { title: '用户归属变更', isShow: true, },
                component: () => import(/* webpackChunkName: "OwnershipList" */ '@/views/userManage/ownership/OwnershipList.vue'),
                children: [
                    {
                        path: '/userManage/ownershipList/ownershipDetails/:id',  // 用户权益变更查询详情
                        name: 'ownershipDetails',
                        meta: { title: '用户归属变更详情'},
                        component: () => import(/* webpackChunkName: "OwnershipDetails" */ '@/views/userManage/ownership/OwnershipDetails.vue'),
                    },
                ]
            },
            {
                path: '/userManage/playDataList',  // 用户播放数据查寻
                name: 'playDataList',
                meta: { title: '用户播放数据', isShow: true, },
                component: () => import(/* webpackChunkName: "PlayDataList" */ '@/views/userManage/playData/PlayDataList.vue'),
                children: [
                    {
                        path: '/userManage/playDataList/shortTermlist/:id',  // 近期学习使用
                        name: 'shortTermlist',
                        meta: { title: '近期学习使用'},
                        component: () => import(/* webpackChunkName: "ShortTermlist" */ '@/views/userManage/playData/ShortTermlist.vue'),
                    },
                    {
                        path: '/userManage/playDataList/coursePlayList/:id',  // 用户课程播放详情
                        name: 'coursePlayList',
                        meta: { title: '用户课程播放详情'},
                        component: () => import(/* webpackChunkName: "CoursePlayList" */ '@/views/userManage/playData/CoursePlayList.vue'),
                    },
                    {
                        path: '/userManage/playDataList/booksPlayList/:id',  // 用户书籍播放详情
                        name: 'booksPlayList',
                        meta: { title: '用户书籍播放详情'},
                        component: () => import(/* webpackChunkName: "BooksPlayList" */ '@/views/userManage/playData/BooksPlayList.vue'),
                    },
                ]
            },
            {
                path: '/userManage/PlayDataListSearch',  // 用户播放数据查寻
                name: 'PlayDataListSearch',
                meta: { title: '用户播放数据（仅搜索）', isShow: true, },
                component: () => import(/* webpackChunkName: "PlayDataList" */ '@/views/userManage/playData/PlayDataListSearch.vue'),
                children: [
                    {
                        path: '/userManage/playDataList/shortTermlist/:id',  // 近期学习使用
                        name: 'shortTermlist',
                        meta: { title: '近期学习使用'},
                        component: () => import(/* webpackChunkName: "ShortTermlist" */ '@/views/userManage/playData/ShortTermlist.vue'),
                    },
                    {
                        path: '/userManage/playDataList/coursePlayList/:id',  // 用户课程播放详情
                        name: 'coursePlayList',
                        meta: { title: '用户课程播放详情'},
                        component: () => import(/* webpackChunkName: "CoursePlayList" */ '@/views/userManage/playData/CoursePlayList.vue'),
                    },
                    {
                        path: '/userManage/playDataList/booksPlayList/:id',  // 用户书籍播放详情
                        name: 'booksPlayList',
                        meta: { title: '用户书籍播放详情'},
                        component: () => import(/* webpackChunkName: "BooksPlayList" */ '@/views/userManage/playData/BooksPlayList.vue'),
                    },
                ]
            },
        ]
    },
]
