/*
 * @Author: dengqiuju@baidu.com
 * @Date: 2022-03-05 15:37:04
 * @Description: 订单管理的路由
 */
// 订单管理的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/videoManage',   // 免费视频管理
        name: 'videoManage',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/videoManage/freeViedo",
        meta: { title: '免费视频管理' },
        children: [
            {
                path: '/videoManage/freeViedo',  // 免费视频
                name: 'freeViedo',
                meta: { title: '免费视频', isShow: true, },
                component: () => import(/* webpackChunkName: "freeViedo" */ '@/views/videoManage/FreeViedo.vue'),
                children: [
                    {
                        path: '/videoManage/freeViedo/addFreeViedo/:id',  // 添加视频
                        name: 'addFreeViedo',
                        meta: { title: '添加/编辑视频',isShow: true},
                        component: () => import(/* webpackChunkName: "addFreeViedo" */ '@/views/videoManage/AddFreeViedo.vue'),
                    },
                ]
            },
            {
                path: '/videoManage/videoClassify',  // 视频分类
                name: 'videoClassify',
                meta: { title: '视频分类', isShow: true, },
                component: () => import(/* webpackChunkName: "videoClassify" */ '@/views/videoManage/VideoClassify.vue'),
            },
            {
                path: '/videoManage/freeAudio',  // 每日一读
                name: 'freeAudio',
                meta: { title: '每日一读', isShow: true, },
                component: () => import('@/views/videoManage/FreeAudio.vue'),
                children: [
                    {
                        path: '/videoManage/freeAudio/addFreeAudio/:id',  // 添加
                        name: 'addFreeAudio',
                        meta: { title: '添加/编辑'},
                        component: () => import('@/views/videoManage/AddFreeAudio.vue'),
                    },
                ]
            },
        ]
    },
]
