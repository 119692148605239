export default [
  {
    path: '/researchLearning',
    name: 'researchLearning',
    component: () => import('@/views/Home.vue'),
    redirect: "/researchLearning/orders",
    meta: { title: '申怡研学' },

    children: [
      {
        path: '/researchLearning/visitorOrders',
        name: 'visitorOrders',
        meta: { title: '抖音来客订单'},
        component: () => import( '@/views/researchLearning/VisitorsOrders/index.vue'),
      },
      {
        path: '/researchLearning/registerInfo',
        name: 'registerInfo',
        meta: { title: '登记信息'},
        component: () => import( '@/views/researchLearning/registerInfo/index.vue'),
      },
      {
        path: '/researchLearning/dateTicket',
        name: 'dateTicket',
        meta: { title: '日历票设置'},
        component: () => import( '@/views/researchLearning/dateTicket/index.vue'),
      },
      
    ]
  }
]
