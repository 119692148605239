/*
 * @Author: dengqiuju@baidu.com
 * @Date: 2022-03-05 15:37:04
 * @Description: 订单管理的路由
 */
// 订单管理的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/circleManage',   // 圈子管理
        name: 'circleManage',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/circleManage/talkList",
        meta: { title: '圈子管理' },
        children: [
            {
                path: '/circleManage/talkList',  // 话题发布
                name: 'talkList',
                meta: { title: '话题发布', isShow: true, },
                component: () => import(/* webpackChunkName: "talkList" */ '@/views/circleManage/TalkList.vue'),
                children: [
                    {
                        path: '/circleManage/talkList/addTalk/:topicNo',  // 添加话题
                        name: 'addTalk',
                        meta: { title: '添加/编辑话题'},
                        component: () => import(/* webpackChunkName: "addTalk" */ '@/views/circleManage/AddTalk.vue'),
                    },
                ]
            },
            {
                path: '/circleManage/contentList',  // 内容列表
                name: 'contentList',
                meta: { title: '内容列表', isShow: true, },
                component: () => import(/* webpackChunkName: "orderDetail" */ '@/views/circleManage/ContentList.vue'),
            },
        ]
    },
]
