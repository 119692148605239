export default {
    namespaced: true,
    state: {
        dictionaryObj: {},
    },
    mutations: {
        changeDictionaryObj(state, payload) {
            state.dictionaryObj =  payload;
        },
    },
    actions: {
    },
}