/*
 * @Author: dengqiuju@baidu.com
 * @Date: 2022-03-05 15:37:04
 * @Description: 订单管理的路由
 */
// 订单管理的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/orderformManage',   // 订单管理
        name: 'orderformManage',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/orderformManage/orderformList",
        meta: { title: '订单管理' },
        children: [
            {
                path: '/orderformManage/orderformList',  // 订单列表路由
                name: 'orderformList',
                meta: { title: '订单列表', isShow: true, },
                component: () => import(/* webpackChunkName: "orderformList" */ '@/views/orderformManage/OrderformList.vue'),
                children: [
                    {
                        path: '/orderformManage/orderformList/orderDetail/:orderNo',  // 订单列表路由
                        name: 'orderDetail',
                        meta: { title: '订单详情' },
                        component: () => import(/* webpackChunkName: "orderDetail" */ '@/views/orderformManage/OrderDetail.vue'),
                    },

                ]
            },
            {
                path: '/orderformManage/OrderformListSearch',  // 订单列表路由
                name: 'OrderformListSearch',
                meta: { title: '订单列表（仅搜索）', isShow: true, },
                component: () => import(/* webpackChunkName: "orderformList" */ '@/views/orderformManage/OrderformListSearch.vue'),
                children: [
                    {
                        path: '/orderformManage/orderformList/orderDetail/:orderNo',  // 订单列表路由
                        name: 'orderDetail',
                        meta: { title: '订单详情' },
                        component: () => import(/* webpackChunkName: "orderDetail" */ '@/views/orderformManage/OrderDetail.vue'),
                    },

                ]
          },
          { 
            path: '/orderformManage/listenerOrder',  // 倾听者订单
            name: 'listenerOrder',
            meta: { title: '倾听者订单' },
            component: () => import('@/views/orderformManage/ListenerOrder.vue'),
          },
            {
                path: '/orderformManage/thirdPartyOrder',  // 第三方订单列表路由
                name: 'thirdPartyOrder',
                meta: { title: '抖店订单' },
                component: () => import(/* webpackChunkName: "thirdPartyOrder" */ '@/views/orderformManage/ThirdPartyOrder.vue'),
            },
            {
                path: '/orderformManage/ThirdPartyOrderSearch',  // 第三方订单列表路由
                name: 'ThirdPartyOrderSearch',
                meta: { title: '抖店订单（仅搜索）' },
                component: () => import(/* webpackChunkName: "thirdPartyOrder" */ '@/views/orderformManage/ThirdPartyOrderSearch.vue'),
            },
            {
                path: '/orderformManage/wxThirdOrder',  // 微信小店订单列表路由
                name: 'wxThirdPartyOrder',
                meta: { title: '微信小店订单' },
                component: () => import(/* webpackChunkName: "wxThirdPartyOrder" */ '@/views/orderformManage/WxThirdPartyOrder.vue'),
            },
            {
                path: '/orderformManage/WxThirdPartyOrderSearch',  // 微信小店订单列表路由
                name: 'WxThirdPartyOrderSearch',
                meta: { title: '微信小店订单' },
                component: () => import(/* webpackChunkName: "wxThirdPartyOrder" */ '@/views/orderformManage/WxThirdPartyOrderSearch.vue'),
            },
            {
                path: '/orderformManage/refundList',  // 退款列表路由
                name: 'refundList',
                meta: { title: '退款列表',isShow: true },
                component: () => import(/* webpackChunkName: "refundList" */ '@/views/orderformManage/RefundList.vue'),
            },
            {
                path: '/orderformManage/productBinding',  // 订单列表路由
                name: 'productBinding',
                meta: { title: '第三方商品绑定', isShow: true },
                component: () => import('@/views/orderformManage/productBinding.vue'),
                children: [
                    {
                        path: 'addBinding/:id',  // 订单列表路由
                        name: 'addBinding',
                        meta: { title: '添加/编辑' },
                        component: () => import('@/views/orderformManage/addBinding.vue'),
                    }
                ]
            },
            {
                path: '/orderformManage/mallOrder',  // 商城订单
                name: 'mallOrder',
                meta: { title: '商城订单', isShow: true },
                component: () => import('@/views/orderformManage/mallOrder/index.vue')
            },
            {
                path: '/orderformManage/mallOrderSearch',  // 商城订单
                name: 'mallOrderSearch',
                meta: { title: '商城订单（仅查询）', isShow: true },
                component: () => import('@/views/orderformManage/mallOrder/mallOrderSearch.vue')
            },
            {
                path: '/orderformManage/redBookOrder',  // 小红书
                name: 'redBookOrder',
                meta: { title: '小红书订单', isShow: true },
                component: () => import(/* webpackChunkName: "wxThirdPartyOrder" */ '@/views/orderformManage/RedBookOrder.vue'),
            }
        ]
    },
]
