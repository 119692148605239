// 角色和权限的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/rolePower',
        name: 'rolePower',
        // redirect: '/rolePower/roleManage',
        component: () => import(/* webpackChunkName: "roleManage" */ '@/views/Home.vue'),
        meta: { title: '角色和权限' },
        children: [
            {
                path: '/rolePower/roleManage',  // 角色管理路由
                name: 'roleManage',
                meta: { title: '角色管理', isShow: true  },
                component: () => import(/* webpackChunkName: "roleManage" */ '@/views/rolePower/RoleManage.vue'),
                children: [
                    {
                        path: "/rolePower/roleManage/addRole/:id", // 添加角色路由
                        name: "addRole",
                        meta: { title: '添加/编辑角色' },
                        component: () => import(/* webpackChunkName: "addRole" */ '@/views/rolePower/AddRole.vue'),
                    },
                ]
            },
            {
                path: '/rolePower/personManage',  // 人员管理路由
                name: 'personManage',
                meta: { title: '人员管理', isShow: true},
                component: () => import(/* webpackChunkName: "personManage" */ '@/views/rolePower/PersonManage.vue'),
                children: [
                    {
                        path: "/rolePower/roleManage/addPerson/:id", // 添加编辑人员路由
                        name: "addPerson",
                        meta: { title: '添加/编辑人员' },
                        component: () => import(/* webpackChunkName: "addPerson" */ '@/views/rolePower/AddPerson.vue'),
                    },
                ]
            },
        ]
    },
]